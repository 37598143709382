import { supabase } from "../utils/supabaseClient"

export const dev = process.env.NODE_ENV !== 'production'

export const server = dev ? 'http://localhost:3000' : 'https://plaza.so'

export const cloudfront = 'https://d28zq0um2frnpx.cloudfront.net'

export const commentFetcher = async arr => {
    const [column, id] = arr
    const { data, error } = await supabase
      .from("main_comment")
      .select(`
        id,
        post,
        data,
        profile (
          username,
          user,
          is_superuser
        )
      `)
      .filter(column, "eq", id)
      return data
}

export const STRIPE_COUNTRIES = [
  'AR', 'AU', 'AT', 'BE', 'BO', 'BG', 'CA',
  'CL', 'CO', 'CR', 'HR', 'CY', 'CZ', 'DK',
  'DO', 'EG', 'EE', 'FI', 'FR', 'DE', 'GR',
  'HK', 'HU', 'IS', 'IN', 'IE', 'IL', 'IT',
  'LV', 'LI', 'LT', 'LU', 'MT', 'MX', 'NL',
  'NZ', 'NO', 'PY', 'PE', 'PL', 'PT', 'RO',
  'SG', 'SK', 'SI', 'ES', 'SE', 'CH', 'TH',
  'TT', 'GB', 'US', 'UY'
]

export const dayObj = {
  1:"Monday",
  2:"Tuesday",
  3:"Wednesday",
  4:"Thursday",
  5:"Friday",
  6:"Saturday",
  7:"Sunday",
}

export const times = [
 
  '05:00 AM',
  '05:15 AM',
  '05:30 AM',
  '05:45 AM',
  '06:00 AM',
  '06:15 AM',
  '06:30 AM',
  '06:45 AM',
  '07:00 AM',
  '07:15 AM',
  '07:30 AM',
  '07:45 AM',
  '08:00 AM',
  '08:15 AM',
  '08:30 AM',
  '08:45 AM',
  '09:00 AM',
  '09:15 AM',
  '09:30 AM',
  '09:45 AM',
  '10:00 AM',
  '10:15 AM',
  '10:30 AM',
  '10:45 AM',
  '11:00 AM',
  '11:15 AM',
  '11:30 AM',
  '11:45 AM',
  '12:00 PM',
  '12:15 PM',
  '12:30 PM',
  '12:45 PM',
  '01:00 PM',
  '01:15 PM',
  '01:30 PM',
  '01:45 PM',
  '02:00 PM',
  '02:15 PM',
  '02:30 PM',
  '02:45 PM',
  '03:00 PM',
  '03:15 PM',
  '03:30 PM',
  '03:45 PM',
  '04:00 PM',
  '04:15 PM',
  '04:30 PM',
  '04:45 PM',
  '05:00 PM',
  '05:15 PM',
  '05:30 PM',
  '05:45 PM',
  '06:00 PM',
  '06:15 PM',
  '06:30 PM',
  '06:45 PM',
  '07:00 PM',
  '07:15 PM',
  '07:30 PM',
  '07:45 PM',
  '08:00 PM',
  '08:15 PM',
  '08:30 PM',
]


export const features = ["Scheduling pages to collect bookings",
"Customizable video chat",
 "Automatic email reminders" ,
 "Upload logo once to brand all assets (scheduling, video chat, email reminders)",
  "Automatic cancellation and rescheduling",
   "Goal tracking",
    "Client Portal"]

export const burnoutFeatures = [
  "Work with a certified coach",
  "Identify and address burnout symptoms",
  "Meet virtually once a week",
  "Get back to feeling energized at work"

]

export const faqs = [
  {
    question: "How many times a week will I meet with my coach?",
    answer:
      "Plans start with meetings once a week over video chat.  Additional meetings are available with an upgraded plan",
  },
  {
      question: "How are coaches qualified?",
      answer:
        "All coaches have prior experience as coaches and have earned either a masters of education or certification through the International Coaching Federation",
    },
    {
      question: "How does burnout coaching work?",
      answer:
        `Clients meet with coaches to identify the sources of burnout then commit to actionable solutions through practicing mindfulness, learning greater self-awareness, and improving conflict resolution.  This may  
        include learning how different personalities are more susceptible to burnout or how subtle life changes can help alleviate burnout `,
    },
    {
      question: "What if I need to reschedule?",
      answer:
        "Clients can check-in with their coaches then reschedule based on their coach's schedule through their client dashboard",
    },
    {
      question: "How much does Plaza burnout coaching cost?",
      answer:
        "Plans start at $600 a month.  Ask your employer if you qualify for reimbursement for burnout coaching",
    },
    {
      question: "What if I have additional questions?",
      answer:
        "Email james@plaza.so to address any additional questions",
    },
]