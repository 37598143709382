import '../styles/scss/global.scss'
import '../styles/globals.css'
import "../styles/scss/tableStyles.scss"
import { AuthProvider } from '../contexts/Auth'
import "prismjs/themes/prism-okaidia.css";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from '../components/Notification'
import { useRouter } from 'next/router'
import { useEffect, useState } from "react";
import Script from 'next/script'
import * as ga from '../lib/gtag'

// optional configuration for react-alerts
const alertOptions = {
  // you can also just use 'bottom right'
  position: positions.BOTTOM_RIGHT,
  timeout: 3000,
  offset: '10px',
  containerStyle: {'zIndex':'9999'},
  // you can also just use 'fade'
  transition: transitions.FADE,
}

export default function MyApp({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
      const handleRouteChange = (url) => {
        ga.pageview(url)
      }
      //When the component is mounted, subscribe to router changes
      //and log those page views
      router.events.on('routeChangeComplete', handleRouteChange)

      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }, [router.events])
 
  const getLayout = Component.getLayout || ((page) => page)
  return  getLayout(
              <Component {...pageProps} />
  
  )
  
}