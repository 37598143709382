import { useContext, useState, useEffect, createContext } from 'react';
import { supabase } from '../utils/supabaseClient';
import { useAlert } from 'react-alert'
import { server } from '../config';

// create a context for authentication
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // create state values for user data and loading
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const customAlert = useAlert();


  useEffect(() => {
    // get session data if there is an active session
    const session = supabase.auth.session();

    setUser(session?.user ?? null);
    setLoading(false);

    // listen for changes to auth
    const { data: listener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user ?? null);
        setLoading(false);
      }
    );

    // cleanup the useEffect hook
    return () => {
      listener?.unsubscribe();
    };
  }, []);

  // create signUp, signIn, signOut functions
  const value = {
    signInGoogle: () => {
      supabase.auth.signIn({ 
        provider: "google"
       }, {
        redirectTo: `${server}/callback/google` 
      })
    },
    signIn: async data => {
      const res = await supabase.auth.signIn(data, {redirectTo:`${server}/userpage?signin=true`})
      return res;
    },
    signOut: () => {
      supabase.auth.signOut({
        redirectTo: {server}
      })
      customAlert.success("Signed out.")
    },
    user,
    loading,
  };

  // use a provider to pass down the value
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// export the useAuth hook
export const useAuth = () => {
  return useContext(AuthContext);
};